import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import React from "react";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
// import axios from "axios";
import { Typography } from "@mui/material";
import { translations } from "./translationMap";
import { TranslatedTypography } from "./bilingualTypography";
import { EmailClickableText } from "./emailClickable";
const TransitionAlerts = () => {
  const [open, setOpen] = React.useState(true);

  return (
    <Box sx={{ width: "100%" }}>
      <Collapse in={open}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          Close me!
        </Alert>
      </Collapse>
    </Box>
  );
};


type FormProps = {
  isSpanish: boolean
}

export const Form:React.FC<FormProps> = (props) => {
  const {isSpanish} = props
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [notification, setNotification] = React.useState("")
  const handleChange = (event:any, setStateName: React.Dispatch<React.SetStateAction<string>>) => {
    setStateName(event.target.value);
    console.log(event.target.value);
  };

  return (
    <Box sx={{ mt: {xs: 5, md: 15}, display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ width: '100%', maxWidth: 'lg', px: 4 }}>
        <Box sx={{
          position: 'relative',
          minWidth: 0,
          overflowWrap: 'break-word',   
          mb: 6,
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'center', sm: 'flex-start' },
        }}>
          <Box sx={{ flex: 1 }}>
            <Box sx={{ textAlign: { xs: 'center', sm: 'left',  md:'center'}, mb: { xs: 3, sm: 4 } }}>
            <TranslatedTypography
            translationMap={translations}
            text={"Want to Contact Us?"}
            isSpanish={isSpanish}
            variant="h2" fontSize={{ xs: 20, sm: 30, md: 40 }} color="blueGray"
          >
          </TranslatedTypography>
            <TranslatedTypography
            translationMap={translations}
            text={"Email us at activetowing89@gmail.com or fill out this form!"}
            isSpanish={isSpanish}
            component="a"
            href={'mailto:activetowing89@gmail.com'}
            variant="h2" fontSize={{ xs: 20, sm: 30, md: 40 }} color="blueGray"
          >
          </TranslatedTypography>
            </Box>
            <Box sx={{ width: '100%' }}>
              <form onSubmit={((e) => console.log(e))}>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between', alignItems: 'center', mb: 12 }}>
                  <TextField
                    id="name"
                    label={isSpanish ? 'Nombre' : "Name"}
                    variant="outlined"
                    sx={{ mb: { xs: 3, md: 0 }, width: { xs: '100%', md: 'calc(50% - 16px)' } }}
                    onChange={(event) => handleChange(event, setName)}
                  />
                  <TextField
                    id="email"
                    label="Email"
                    variant="outlined"
                    sx={{ width: { xs: '100%', md: 'calc(50% - 16px)' } }}
                    onChange={(event) => handleChange(event, setEmail)}
                  />
                </Box>
                <TextField
                  id="message"
                  label={isSpanish ? 'Mensaje' : "Message"}
                  multiline
                  rows={10}
                  variant="outlined"
                  sx={{ width: '100%', mb: 8 }}
                  onChange={(event) => handleChange(event, setMessage)}
                />
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <IconButton
                    aria-label="send"
                    color="secondary"
                    onClick={((e) => console.log(e))}
                  >
                    {notification==="success" ? (
                      <Alert variant="standard"/>
                    ) : (<></>)}
                    <SendIcon />
                  </IconButton>
                </Box>
              </form>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
