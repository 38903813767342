import { Box, Divider, Grid } from "@mui/material";
import * as React from "react";
import { TypographyAccordian } from "src/components/accordian";
import { TranslatedTypography } from "src/components/bilingualTypography";
import { Form } from "src/components/form";
import GoogleMapsEmbed from "src/components/googleMaps.jsx";
import { Nav } from "src/components/nav";
import { translations } from "src/components/translationMap";
import { Typewriter } from "src/components/typewriter";
import car from "../resources/car1.jpeg";
import city from "../resources/denver.jpg";

declare global {
  interface navigator {
    language: string;
  }
}

export const Home: React.FC = () => {
  const [isSpanish, setIsSpanish] = React.useState<boolean>(false);
  const [lang, setLang] = React.useState<string>("");

  React.useEffect(() => {
    // @ts-ignore
    const userLanguage: string = navigator.language;
    if (userLanguage && userLanguage.toLowerCase().includes("es")) {
      setIsSpanish(true);
    }
    console.log(lang);
  }, []);

  return (
    <>
      <Nav isSpanish={isSpanish} setIsSpanish={setIsSpanish} />
      <Box sx={{ p: 2 }}>
        <Grid
          container
          spacing={2}
          alignItems="flex-start"
          justifyContent="center"
        >
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              textAlign: { md: "left", xs: "center" },
              margin: "auto",
              mt: { xs: "8%" },
            }}
          >
            <Typewriter
              text="Fast and Effecient Towing Services"
              isSpanish={isSpanish}
            />
          </Grid>
          <Grid id="#home" item xs={12} md={6} sx={{ textAlign: "center" }}>
            <Box
              component={"img"}
              src={car}
              sx={{
                width: "75%",
                marginTop: { xs: "5%", md: "20%" },
                opacity: "85%",
                borderWidth: "3%",
                borderStyle: "solid",
                borderImage:
                  "linear-gradient(to right, darkblue, darkorchid) 1",
              }}
            />
          </Grid>
        </Grid>
        <Divider
          sx={{
            mt: "10vh",
            mb: "10vh",
            borderColor: "#FFFFFF",
            border: "2px",
            borderStyle: "solid",
            width: "100vw",
            borderRadius: "100",
          }}
        />
        <Grid
          container
          spacing={2}
          alignItems="flex-start"
          justifyContent="center"
        >
          <Grid item xs={12} md={6} sx={{ textAlign: "center" }}>
            <Box
              component={"img"}
              src={city}
              sx={{
                width: "75%",
                marginTop: { xs: "5%", md: "20%" },
                opacity: "85%",
                borderWidth: "3%",
                borderStyle: "solid",
                borderImage:
                  "linear-gradient(to right, darkblue, darkorchid) 1",
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ textAlign: { md: "left", xs: "center" } }}
          >
            <Typewriter
              text="Need a Tow? Call 720-338-0467"
              isSpanish={isSpanish}
            />
          </Grid>
        </Grid>
        <Divider
          sx={{
            mt: "10vh",
            mb: "10vh",
            borderColor: "#FFFFFF",
            border: "2px",
            borderStyle: "solid",
            width: "100vw",
            borderRadius: "100",
          }}
        />
        <Grid
          container
          spacing={2}
          direction={{ xs: "column", md: "row" }}
          justifyContent="center"
          alignItems="center"
          sx={{
            p: { xs: 2, sm: 4, md: 6 },
            maxWidth: "1200px",
            margin: "0 auto",
          }}
        >
          <Grid item xs={12} md={4}>
            <TypographyAccordian
              isSpanish={isSpanish}
              title={"Towing"}
              content={`Premier towing company in Denver, CO. 
            24/7 service across the city with Spanish-friendly options. 
            Top-notch support for any situation, including towing, 
            jumpstarts, and roadside assistance. 
            Contact us now for the best towing service in Denver.`}
              shortText={"towbox"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TypographyAccordian
              isSpanish={isSpanish}
              title={"Junk Cars"}
              content={`In the Denver area we buy junk cars at fair prices. 
            Our team is committed to providing top-notch service and support, 
            and we're Spanish-friendly. 
            Call us today for the best towing and junk 
            car buying services in Denver!`}
              shortText={"junkbox"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TypographyAccordian
              isSpanish={isSpanish}
              title={"Road Service"}
              content={`Our tow truck company provides 24/7 
            roadside assistance to get you back on the road quickly. 
            We offer fast response times and highly trained 
            professionals who can handle any situation. 
            From jumpstarts and lockouts to tire changes and fuel 
            delivery, we have the expertise to solve your problems. 
            No matter the time of day or night, we're here to help 
            you when you need it most.`}
              shortText={"roadbox"}
            />
          </Grid>
        </Grid>
        <Divider
          sx={{
            mt: "10vh",
            mb: "10vh",
            borderColor: "#FFFFFF",
            border: "2px",
            borderStyle: "solid",
            width: "100vw",
            borderRadius: "100",
          }}
        />
        <Box sx={{ flex: 1 }}>
          <Box
            sx={{
              textAlign: { xs: "center", sm: "left", md: "center" },
              mb: { xs: 0, sm: 0 },
            }}
          >
            <TranslatedTypography
              text={"Contact"}
              translationMap={translations}
              isSpanish={isSpanish}
              variant="h2"
              fontSize={{ xs: 30, sm: 40, md: 60 }}
              color="blueGray"
              id="contact"
            />
          </Box>
        </Box>
        <Form isSpanish={isSpanish} />
        <GoogleMapsEmbed isSpanish={isSpanish} />
      </Box>
    </>
  );
};
