import React, { useRef, useEffect } from "react";
import { Grid, Box, TextField } from "@mui/material";
import { Typography } from "@mui/material";
import { Typewriter } from "./typewriter";



function Map(props) {
  const {isSpanish} = props

  // Create a ref to the map element
  const mapRef = useRef(null);

  // Initialize the map when the component mounts
  useEffect(() => {
    // Load the Google Maps API
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDGX2SL7xaKmxpUyX3TP4W_JtPeIPMlJwQ&callback=initMap`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);

    // Initialize the map and satellite image
    window.initMap = function () {
      const map = new window.google.maps.Map(mapRef.current, {
        center: { lat: 39.73715, lng: -104.989174 },
        zoom: 11,
        // mapTypeId: 'satellite'
      });
      const imageOverlay = new window.google.maps.GroundOverlay(
        `https://maps.googleapis.com/maps/api/staticmap?zoom=10&size=640x480&maptype=satellite&key=AIzaSyDGX2SL7xaKmxpUyX3TP4W_JtPeIPMlJwQ`,
        map.getBounds(),
        { clickable: true }
      );
      imageOverlay.setMap(map);
    };
  }, []);

  // Render the map element
  return (
<Grid
  container
  justifyContent="center"
  spacing={5}
  sx={{
    ml: { sm: 0, md: 0 },
    flexDirection: { xs: "column", md: "column" },
    textAlign: "center"
  }}
>
  <Grid item xs={11} md={14} lg={12} sx={{alignContent:"center", justifyContent: "center" }}>
  <Typewriter text="We service all of Denver and the surrounding areas!" isSpanish={isSpanish}/>

  </Grid>
  <Grid item xs={12} md={11} lg={10} sx={{ alignContent:"center",justifyContent: "center" }}>
    <Box
      ref={mapRef}
      sx={{ width: "100%", height: { xs: "400px", md: "500px" } }}
    ></Box>
  </Grid>
</Grid>
  );
}

export default Map;
