import { TypographyProps } from "@mui/material/Typography";
import { useState } from "react";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { TranslationMap } from "./translationMap";

export const translateText = (
  text: string,
  isSpanish: boolean,
  translationMap: TranslationMap,
  shortText?: string | undefined
) => {
  let spanishText = "";
  if (isSpanish && shortText && shortText == "junkbox") {
    spanishText = translationMap["junkbox"];
  } else if (isSpanish && shortText && shortText == "towbox") {
    spanishText = translationMap["towbox"];
  } else if (isSpanish && shortText && shortText == "roadbox") {
    spanishText = translationMap["roadbox"];
  } else {
    spanishText = isSpanish ? translationMap[text.toLowerCase()] || text : text;
  }
  return spanishText;
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: "darkslategray",
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  transition: "color 0.3s ease-in-out",
  "&:hover": {
    color: "#9932CC",
    filter: "blur(2px)",
  },
}));

type TranslatedTypographyProps = {
  text: string;
  isSpanish: boolean;
  translationMap: TranslationMap;
  shortText?: string;
} & TypographyProps & { component?: string; href?: string };

export const TranslatedTypography = ({
  text,
  isSpanish,
  translationMap,
  shortText,
  ...rest
}: TranslatedTypographyProps) => {
  const translatedText = translateText(
    text,
    isSpanish,
    translationMap,
    shortText
  );

  return <StyledTypography {...rest}>{translatedText}</StyledTypography>;
};
