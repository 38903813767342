import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import CarRepairIcon from "@mui/icons-material/CarRepair";
import Switch from "@mui/material/Switch";
import { translations } from "./translationMap";
import { TranslatedTypography } from "./bilingualTypography";
const pages = ["Home", "Contact"];

type NavProps = {
  isSpanish: boolean;
  setIsSpanish: (x: boolean) => void;
};

export const Nav: React.FC<NavProps> = (props) => {
  const { isSpanish, setIsSpanish } = props;
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleLanguageToggle = () => {
    setIsSpanish(!isSpanish);
  };
  return (
    <AppBar color="default" position="fixed" sx={{ width: "100%" }}>
      <Container maxWidth={false} sx={{ width: "100vw", mt: 0 }}>
        <Toolbar disableGutters>
          <CarRepairIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} />
          <TranslatedTypography
            translationMap={translations}
            text={"Active Towing"}
            isSpanish={isSpanish}
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontWeight: 700,
              color: "inherit",
              textDecoration: "none",
            }}
          ></TranslatedTypography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <TranslatedTypography
                    translationMap={translations}
                    text={page}
                    isSpanish={isSpanish}
                    textAlign="center"
                    component="a"
                    href={`#${page.toLocaleLowerCase()}`}
                  ></TranslatedTypography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <CarRepairIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} />
          <TranslatedTypography
          translationMap={translations}
          isSpanish={isSpanish}
          text={"Active Towing"}
            variant="h5"
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontWeight: 200,
              color: "inherit",
              textDecoration: "none"
            }}
          >
          </TranslatedTypography>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <TranslatedTypography
              key={page}
                isSpanish={isSpanish}
                translationMap={translations}
                text={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                {page}
              </TranslatedTypography>
            ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: { xs: "center", md: "flex-end" },
              width: { xs: "100%", md: "auto" },
            }}
          >
            <Typography sx={{ mr: 1 }}>English</Typography>
            <Switch checked={isSpanish} onChange={handleLanguageToggle} />
            <Typography sx={{ ml: 1 }}>Espanol</Typography>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
