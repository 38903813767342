import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { TranslatedTypography } from "./bilingualTypography";
import { translations } from "./translationMap";

interface CustomizedAccordionProps {
  title: string;
  content: string;
  isSpanish: boolean;
  shortText?: string | undefined;
}

const CustomAccordion = styled(Accordion)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export const TypographyAccordian: React.FC<CustomizedAccordionProps> = ({
  title,
  content,
  isSpanish,
  shortText,
}: CustomizedAccordionProps) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleToggle = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <div>
      <CustomAccordion expanded={expanded} onChange={handleToggle}>
        <CustomAccordionSummary
          expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
        >
          <TranslatedTypography
            translationMap={translations}
            text={title}
            isSpanish={isSpanish}
          >
          </TranslatedTypography>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
        <TranslatedTypography
            translationMap={translations}
            text={content}
            isSpanish={isSpanish}
            shortText={shortText}
          >
          </TranslatedTypography>
        </CustomAccordionDetails>
      </CustomAccordion>
    </div>
  );
};
