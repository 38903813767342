export type TranslationMap = { [key: string]: string };

export const translations: TranslationMap = {
  "fast and effecient towing services":
    "SERVICIOS DE REMOLQUE RÁPIDOS Y EFICIENTES",
  "active towing": "Remolque activo",
  "need a tow? call 720-338-0467":
    "¿NECESITAS UN REMOLQUE? LLAME AL 720-338-0467",
  towing: "Remolque",
  "junk cars": "Coches chatarra",
  "road service": "Servicio de Carretera",
  towbox: `Compañía de remolque de primer 
    nivel en Denver, CO. Servicio 24/7 en toda la ciudad 
    con opciones amigables con el español. 
    Soporte de primer nivel para cualquier situación, incluyendo 
    remolque, arranques y asistencia en carretera. 
    Contáctenos ahora para el mejor servicio de remolque en Denver.`,
  junkbox: `En el área de Denver compramos autos chatarra a precios justos. 
  Nuestro equipo se compromete a proporcionar un servicio y soporte de primer nivel, 
  y somos amigables con el español. 
  Llámenos hoy para el mejor remolque y basura 
  servicios de compra de coches en Denver!`,
  roadbox: `Nuestra empresa de grúas proporciona asistencia en carretera 24/7 
    para devolverte rápidamente a la carretera. Ofrecemos tiempos de respuesta rápidos y 
    profesionales altamente capacitados que pueden manejar cualquier situación.
     Desde arranques y bloqueos hasta cambios de neumáticos y entrega de combustible, 
     tenemos la experiencia para resolver tus problemas. No importa la 
     hora del día o de la noche, 
    estamos aquí para ayudarte cuando más lo necesitas.`,
  "want to contact us?": "¿Quieres contactarnos?",
  "email us at activetowing89@gmail.com or fill out this form!": "Envíenos un correo electrónico a activetowing89@gmail.com o complete este formulario.",
  "we service all of denver and the surrounding areas!":
    "¡SERVICIAMOS TODA DENVER Y SUS ÁREAS CIRCUNDANTES!",
  home: "Inicio",
  contact: "Contacto",
};
