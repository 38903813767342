import { useState, useEffect, Dispatch } from "react";
import { Typography } from "@mui/material";
import {TranslatedTypography, translateText} from './bilingualTypography'
import { translations } from "./translationMap";
interface TypewriterProps {
  text: string;
  delay?: number;
  isSpanish?: boolean
}

export const Typewriter: React.FC<TypewriterProps> = ({ text, isSpanish = false, delay = 50 }) => {
  const [displayText, setDisplayText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  
  useEffect(() => {
    const interval = setInterval(() => {
      setDisplayText(translateText(text, isSpanish, translations).substring(0, currentIndex));
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }, delay);

    return () => clearInterval(interval);
  }, [text, currentIndex, delay]);

  return (
    <TranslatedTypography
      translationMap={translations}
      text={displayText}
      isSpanish={isSpanish}
      variant="h4"
      sx={{
        marginTop: "15%",
        fontWeight: "bold",
        textTransform: "uppercase",
        letterSpacing: "2px",
        fontSize: { xs: "24px", md: "40px" },
        color: "#333",
        textAlign: { xs: "center", md: "left" },
      }}
    >
      {displayText}
    </TranslatedTypography>
  );
};
