import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#2F4F4F", // dark blue
    },
    secondary: {
      main: "#9932CC", // dark orchid
    },
    text: {
      primary: "#333",
      secondary: "#666",
      disabled: "#999",
    },
    background: {
      default: "#f0f0f0",
      paper: "#fff",
    },
  },
});

export default theme;